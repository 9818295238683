import React, {Component} from "react";
import "./history.css";
import HistoryEntry, {HistoryEntryProps} from "./historyEntry";
import LanguageDetails , {LanguageDetailsProps} from "./languageDetails";
import {faCertificate, faLocationArrow, faScroll} from "@fortawesome/free-solid-svg-icons";
import {unstable_renderSubtreeIntoContainer} from "react-dom";

export default class History extends Component {

    static workExperience : HistoryEntryProps[] = [
        {
          image: "ecabslogo.svg",
          title: "eCabs Technologies Limited (prev. Cuorium)",
          link: "https://www.ecabs.com.mt/",
          description: [
              <p key={"ecabs-0"}>Managed a multi-country, multi-discipline technology department to facilitate the implementation and upkeep of a taxi management system.</p>,
              <p key={"ecabs-1"}>Involved in and overseeing the transformation of the local self-hosted system into a Google Cloud-based multi-project, multi-tenant, microservice platform. Integration included technologies such as Java, GoLang, Angular, Kubernetes (GKE, Helm), Terraform, together with several cloud-based solutions such as Pub/Sub, CloudArmor, Cloud Storage, Big Query, Cloud SQL, Memorystore (Redis), Firebase, and CI/CD tools such as Jenkins and Artifact Registry.</p>,
              <p key={"ecabs-2"}>Managed the entire technology team, including Backend Teams (primarily Java), Mobile Team (Android and iOS native), Frontend (Angular), QA (Manual & Automation), NOC (Cloud-based System Monitoring), Data (PowerBI).</p>,
              <p key={"ecabs-3"}>Scaled up the team from ~10 to ~70 members over 3 years, introducing a NOC team for live process monitoring. Achieved through in-house specialization and contractor hiring, fostering a remote-first culture due to the multi-country nature of the team.</p>,
              <p key={"ecabs-4"}>Introduced processes to organize workflows across all levels for better management and planning of roadmap work, enabling clearer goal focus. Streamlined all stages from solution conception/design through development, release, and monitoring. Main goals were to improve team cohesiveness in an automated fashion, ensuring independence and voice for all teams.</p>,
              <p key={"ecabs-5"}>Developed team members' interpersonal and academic growth through individual career path attention and facilitating necessary training.</p>,
              <p key={"ecabs-6"}>Managed the ISO 27001 certification process.</p>,
              <p key={"ecabs-7"}>Managed initial interaction with 3rd party vendors/suppliers to streamline communication, reduce bottlenecks, and ensure observable work allocation.</p>,
              <p key={"ecabs-8"}>Acted as a bridge between the Executive Committee and the tech team to provide insight into the current and forecasted workload. Consolidated the Technology Vision based on investor requirements, encouraging innovation and tech stack enhancement for optimal results.</p>,
              <p key={"ecabs-9"}>Other technologies used include the Atlassian Stack (JIRA, Confluence, Bitbucket, OpsGenie) and monday.com.</p>
          ],
          subEntries: [
              {
                  subtitle: "(acting CTO)",
                  dateRange: "April 2024 - Current",
                  sterileDateRange: "4 months (ongoing)",
                  fontawesome: faScroll
              },
              {
                  subtitle: "Head of Development",
                  dateRange: "Oct 2021 - Current",
                  sterileDateRange: "3 years (ongoing)",
                  fontawesome: faScroll
              },
              {
                  subtitle: "Full Stack Developer",
                  dateRange: "Aug 2021 - Oct 2022",
                  sterileDateRange: "1 year 2 months",
                  fontawesome: faScroll
              }
          ]
        },
        {
            image : "relax.png",
            title: "Relax Gaming",
            link : "https://relax-gaming.com/",
            description: [
                <p key={"relax-0"}>Led the implementation of game engine backend APIs by converting Mathematical Spec Documents into Java APIs, including RTP matching and construction of Round History images.</p>,
                <p key={"relax-1"}>Organized and structured internal team workflows on JIRA and core coding practices and infrastructure such as Gitflow, Maven, and Sonatype Nexus.</p>,
                <p key={"relax-2"}>Managed release management in a semi-automated process and coordinated development issues across multiple business teams.</p>,
                <p key={"relax-3"}>Handled internal administration of developer environment VMs to deploy development tools such as Jenkins and SonarQube through Docker.</p>,
                <p key={"relax-4"}>Managed a team of 2 developers, which scaled up to 8 over time, while closely collaborating with the QA team.</p>
            ],
            subEntries: [
                {
                    subtitle: "Lead Game Engine Developer",
                    dateRange: "Feb 2020 - 4 Aug 2021",
                    sterileDateRange: "1 year 6 months",
                    fontawesome: faScroll
                },
                {
                    subtitle: "Game Engine Developer",
                    dateRange: "Mar 2018 - Feb 2020",
                    sterileDateRange: "2 years",
                    fontawesome: faScroll
                }
            ]
        },
        {
          image: "tctc.png",
          title: "TCTC",
          link: "https://www.tctcmalta.com/en/home.htm",
          description: [
              <p key={"tctc-0"}>Taught robotics and programming concepts to children aged 7 to 14 during summer sessions.</p>
          ],
          subEntries: [
              {
                  subtitle: "Robotica Instructor",
                  dateRange: "July - September 2021",
                  sterileDateRange: "3 Months (Summer course)",
                  fontawesome: faScroll
              },
              {
                  subtitle: "Programming Instructor",
                  dateRange: "July - September 2021",
                  sterileDateRange: "3 Months (Summer course)",
                  fontawesome: faScroll
              }
          ]
        },
        {
            image : "lottostarlet.png",
            contrastImageBackground : true,
            title: "LottoStarlet",
            description: [
                <p key={"lotto-0"}>Joined the startup as a developer and progressed to become the manager of the tech team. Led backend implementation for a secondary lottery website using Java and Spring, from initial development through post-live maintenance.</p>,
                <p key={"lotto-1"}>Designed the architecture spanning both Java backend and Angular 2 frontend, and oversaw server infrastructure administration for both live and development environments, utilizing a combination of Docker-based and raw-OS installations.</p>,
                <p key={"lotto-2"}>Managed the certification process through MGA (Malta Gaming Authority).</p>,
                <p key={"lotto-3"}>Led the team's growth from 2 developers to 7 developers, along with additional roles such as QA and Design, while collaborating closely with Marketing to deliver comprehensive solutions.</p>
            ],subEntries: [
                {
                    subtitle: "Software Architect",
                    dateRange: "30 Jun 2017 - 30 Jan 2018",
                    sterileDateRange: "1 year",
                    fontawesome: faScroll
                },
                {
                    subtitle: "Senior Backend Developer",
                    dateRange: "24 Apr 2017 - 20 Jun 2017",
                    sterileDateRange: "2 months",
                    fontawesome: faScroll
                }
            ]
        },
        {
            image : "imagina.png",
            contrastImageBackground : true,
            title: "Imagina Gaming (prev. Casinova)",
            description: [
                <p key={"imagina-0"}>Led the ground-up implementation of a Microservice-based Slot Gaming Platform using Java, Akka, and actors for the backend, with ReactJS for the frontend.</p>,
                <p key={"imagina-1"}>Constructed an internal real-time Data-Warehousing and Reporting infrastructure.</p>,
                <p key={"imagina-2"}>Set up the internal development environment on servers, including tools like Jenkins, JIRA, and Jetbrains Upsource.</p>,
                <p key={"imagina-3"}>Joined as one of the initial three members of the startup development team, which later scaled up to six members.</p>
            ],
            subEntries: [
                {
                    subtitle: "Software Engineer",
                    dateRange: "Jun 2016 - Apr 2017",
                    sterileDateRange: "1 year",
                    fontawesome: faScroll
                }
            ]
        },
        {
            image : "ixaris.svg",
            title: "Ixaris Systems",
            link : "https://www.nium.com/",
            description:[
                <p key={"ixaris-0"}>Contributed to the implementation of the Core layer of a Virtual Credit Card Financial Platform using Java and AngularJS.</p>,
                <p key={"ixaris-1"}>Assisted in setting up tools such as GitLab and participated in the automation of the release process using Puppet and Ansible.</p>,
                <p key={"ixaris-2"}>Participated in Proof of Concept testing of technologies for a microservice-based architecture migration, including Kubernetes, Message Queues, asynchronous APIs, and Future-based implementations.</p>,
                <p key={"ixaris-4"}>Worked as part of a core team of 8 developers, within a larger group of 30+ developers and QA.</p>
            ],
            subEntries: [
                {
                    subtitle: "Junior Software Engineer",
                    dateRange: "Jun 2014 - Jun 2016",
                    sterileDateRange: "2 years",
                    fontawesome: faScroll
                }
            ]
        },
        {
            image : "hob.png",
            contrastImageBackground : true,
            title: "HOB Software",
            link: "https://en.wikipedia.org/wiki/HOB_GmbH_%26_Co_KG",
            description: [
                <p key={"hob-0"}>Contributed to the implementation of a Java-based VPN testing tool for an internal L2TP/IPSec VPN running on Ubuntu.</p>,
                <p key={"hob-1"}>Contributed to the implementation of a JSF-based internal HR and payroll management tool.</p>,
                <p key={"hob-2"}>Assisted in the management of servers and automation of processes using Python.</p>,
                <p key={"hob-3"}>Worked as part of a team of 2 developers, within a larger group of 15+ developers.</p>
            ],
            subEntries: [
                {
                    subtitle: "Junior System Administrator",
                    dateRange: "Feb 2014 - Jun 2014",
                    sterileDateRange: "4 months",
                    fontawesome: faScroll
                },
                {
                    subtitle: "Software Developer",
                    dateRange: "Jun 2011 - Sep 2013",
                    sterileDateRange: "2 years 4 months",
                    fontawesome: faScroll
                }
            ]
        }
    ];


    static education : HistoryEntryProps[] = [
        {
            title: "Bsc. ICT Software Development",
            description: "Bachelors degree in Software Development which covers core concepts of software development varying from more theoretical subjects such as Logic, Formal languages and Automata; as well as other more practical subjects such as Web and Java Development",
            subEntries: [
                {
                    subtitle: "University of Malta",
                    dateRange: "Oct 2012 - Jun 2015",
                    sterileDateRange: "2015",
                    fontawesome: faLocationArrow
                }
            ]
        },
        {
            title: "Game Programming 1",
            description: "Game Programming course introducing generic video game development concepts. Language used was Adobe Flash.",
            subEntries: [
                {
                    subtitle: "Holistic IT Group",
                    dateRange: "Jul - Sep 2010",
                    sterileDateRange: "2010",
                    fontawesome: faLocationArrow
                }
            ]
        },
        {
            title: "ECDL",
            description: "The European Computer Driving License with advanced coverage Microsoft Office held at Savio College",
            subEntries: [
                {
                    subtitle: "ECDL Advanced - Word, Presentation",
                    dateRange: "2008 - 2009",
                    sterileDateRange: "2009",
                    fontawesome: faLocationArrow
                },
                {
                    subtitle: "ECDL",
                    dateRange: "2005 - 2007",
                    sterileDateRange: "2007",
                    fontawesome: faLocationArrow
                }

            ]
        },
        {
            title: "Square Enix Seminars",
            description: "Seminars on the visual of Final Fantasy by the art director of Final Fantasy Type-0 HD, and on the creative process behind the CG movies of Square Enix by the general manager of Visual Works (Square Enix's own CG movie production studio)",
            subEntries: [
                {
                    subtitle: "Visuals of Final Fantasy by Yusuke Naora at the SMU Guildhall",
                    dateRange: "27 Mar 2015",
                    fontawesome: faLocationArrow
                },
                {
                    subtitle: "Creaive Process behind CG movies of Square Enix by Kazuyuki Ikumori at the University of Southern California",
                    dateRange: "12 Mar 2015",
                    fontawesome: faLocationArrow
                }
            ]
        },
        {
            title: "Japanese Module I",
            description: "",
            subEntries: [
                {
                    subtitle: "Malta University Language School",
                    dateRange: "Apr 2017 - Jun 2017",
                    sterileDateRange: "2017",
                    fontawesome: faLocationArrow
                }
            ]
        },
        {
            title: "iGaming NEXT Malta Conference",
            description: "Presentations regarding new Gaming trends and available Technologies by Betconstruct, Quickspin, Shazam, NetEnt,  Microsoft, Amazon game tech.",
            subEntries: [
                {
                    subtitle: "Eden Place, St Julians, Malta",
                    dateRange: "10 Sep 2019",
                    fontawesome: faLocationArrow
                }
            ]
        },
        {
            title: "Delta Summit",
            description: "Presentations regarding the development of Artificial Intelligence, Blockchain and Gaming",
            subEntries: [
                {
                    subtitle: "MFCC, Malta",
                    dateRange: "2 - 4 Oct 2019",
                    fontawesome: faLocationArrow
                }
            ]
        },
        {
            title: "ICE Totally Gaming Conference",
            description: "Stands and seminars relating to current trends in gaming",
            subEntries: [
                {
                    subtitle: "ICE, London",
                    dateRange: "Feb 2017",
                    fontawesome: faLocationArrow
                }
            ]
        }
    ];

    static awardsAndCertifications : HistoryEntryProps[] = [
        {
            title: "Fire safety certification",
            description: "Fire handling and emergency measures training",
            subEntries: [
                {
                    subtitle: "Relax Gaming",
                    dateRange: "2020",
                    fontawesome: faCertificate
                }
            ]
        },
        {
            title: "Game of Codes by ICTSA Malta",
            description: "1st Place in student / industry category of competitions",
            subEntries: [
                {
                    subtitle: "Origami Folding algorithm - Student & Industry category",
                    dateRange: "29 Nov - 1 Dec 2013",
                    fontawesome: faCertificate
                },
                {
                    subtitle: "Collage Making algorithm - Industry category",
                    dateRange: "20-22 Nov 2015",
                    fontawesome: faCertificate
                }
            ]
        },
        {
            title: "Calligraphy",
            description: "Degree Plus course on basic Calligraphy",
            subEntries: [
                {
                    subtitle: "University of Malta",
                    dateRange: "Oct 2011 - Jun 2012",
                    fontawesome: faCertificate
                }
            ]
        },
        {
            title: "Food Handling B",
            description: "Certification allowing food handling",
            subEntries: [
                {
                    subtitle: "St. Bernard's Health & Safety",
                    dateRange: "Aug 2018",
                    fontawesome: faCertificate
                },
                {
                    subtitle: "Stephen Mifsud Msc",
                    dateRange: "Jun 2009",
                    fontawesome: faCertificate
                }
            ]
        },
        {
            title: "Piano & Music Theory",
            description: "Grade 5 Piano and Music Theory",
            subEntries: [
                {
                    subtitle: "Trinity College London",
                    dateRange: "May - Jun 2008",
                    fontawesome: faCertificate
                }
            ]
        }
    ];

    static languages : LanguageDetailsProps[] = [
        {
            name: "English",
            icon: "gb",
            rank: 5
        },
        {
            name: "Maltese",
            icon: "mt",
            rank: 5
        },
        {
            name: "Italian",
            icon: "it",
            rank: 4
        },
        {
            name: "French",
            icon: "fr",
            rank: 3
        },
        {
            name: "Japanese",
            icon: "jp",
            rank: 1
        }
    ];

    range(size:number):ReadonlyArray<number> {
        // @ts-ignore
        return [...Array(size).keys()];
    }


    render() {
        return (<div className="History">
            <div className="History-Content">
                <h1>Work Experience</h1>
                {
                    History.workExperience.map(w => <HistoryEntry key={"History-Entry-Work-"+w.title.replace(/\s+/g, '')} parentKey={"History-Entry-Work-"+w.title.replace(/\s+/g, '')} {...w}></HistoryEntry>)
                }
                <h1>Education</h1>
                {
                    History.education.map(w => <HistoryEntry key={"History-Entry-Education-"+w.title.replace(/\s+/g, '')} parentKey={"History-Entry-Education-"+w.title.replace(/\s+/g, '')} expandable={true} {...w}></HistoryEntry>)
                }
                <h1>Certification</h1>
                {
                    History.awardsAndCertifications.map(w => <HistoryEntry key={"History-Entry-Awards-"+w.title.replace(/\s+/g, '')} parentKey={"History-Entry-Awards-"+w.title.replace(/\s+/g, '')} expandable={true} {...w}></HistoryEntry>)
                }
                <h1>Fluency</h1>
                {
                    // we group each language into sets of 3
                    this.range(History.languages.length)
                        .map(i => Math.floor(i/2))
                        .filter((v,i,s) => s.indexOf(v) === i) // distinct
                        .map(i => {
                            return <div className="History-Language-Wrapper" key={"History-Languages-Wrapper-"+i}>
                                {
                                    this.range(2).map(y => i*2+y).filter(y => y < History.languages.length)
                                        .map(y => {
                                            let w : LanguageDetailsProps = History.languages[y];
                                            return <div  key={"Lang-"+y} className={"LanguageWrapperDiv"}><LanguageDetails key={"History-Entry-Language"+w.name} {...w}></LanguageDetails></div>
                                        })
                                }
                            </div>;
                        })
                }

           </div>
        </div>)
    }
}